@import "../../utils/styles/variables/combined-vars.scss";
@import "../../utils/styles/break-points.scss";
.history {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: $spacing;
  &__tab {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 25px;
      background: $light; /* make scrollbar transparent */
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: $primary; /* make scrollbar transparent */
    }
    width: 100%;
    height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: $spacing;
    background: #ffffff80;
    border-radius: 5px;
    backdrop-filter: blur(5px);
    padding: $spacing;
    border: 2px solid $white;
    box-shadow: 0 0 5px 0 #94949440, inset 0 0 10px 0 #7e7e7e30;
    list-style-type: none;
    &__item {
      display: flex;
      padding: $spacing;
      gap: $spacing;
      justify-content: space-between;
      border-bottom: 1px solid $subtle;
      width: 100%;
      height: fit-content;
      &__title {
        font-weight: 700;
        width: 100%;
      }
    }
  }
}
