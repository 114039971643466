.btn {
  padding: $spacing-text-xs $spacing;
  background: $primary;
  border-radius: 2px;
  cursor: pointer;
  color: $white;
  transition: all 0.2s ease;
  text-decoration: none;
  position: relative;
  user-select: none;
  height: fit-content;
  width: fit-content;
  &:hover {
    background: $ternary;
  }
  &--secondary {
    &:hover {
      background: $highlight_secondary;
    }
  }
  &--subtleHover {
    &:hover {
      background: $highlight_primary;
    }
  }
  &__share {
    &::after {
      position: absolute;
      content: "Copied!";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $success;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      transition: all 0.3s ease;
      clip-path: circle(0% at 0% 0%);
    }
  }
  &__copied {
    &::after {
      clip-path: circle(150% at 0% 100%);
    }
  }
}
