.flex {
  display: flex;
  position: relative;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.space-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.block {
  display: block;
}
.fit-content {
  width: fit-content;
  height: fit-content;
}
