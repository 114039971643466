@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "./utils/styles/combined.scss";
html {
  font-size: 12px;
  @include respond-to("medium") {
    font-size: 16px;
  }
}
body {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  background: url("./assets/images/home.png") no-repeat center center fixed;
  background-size: cover;
}
* {
  color: $primary;
}
