// text
$spacing-text-xs: 0.5rem !default;
$spacing-text-sm: 0.75rem !default;
$spacing-text: 1rem !default;
$spacing-text-md: 1.5rem !default;
$spacing-text-lg: 2rem !default;
$spacing-text-xl: 3rem !default;

// white space
$spacing-xs: 0.5rem !default;
$spacing-sm: 0.75rem !default;
$spacing: 1rem !default;
$spacing-md: 1.5rem !default;
$spacing-lg: 2rem !default;
$spacing-xl: 3rem !default;
$spacing-xxl: 4rem !default;
$spacing-xxxl: 5rem !default;
$spacing-xxxxl: 6rem !default;
$spacing-xxxxxl: 7rem !default;
$spacing-xxxxxxl: 8rem !default;
