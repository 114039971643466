$primary: #000;
$secondary: #41b8df;
$light: #f8f8f8;
$ternary: #6dbce1;
$highlight_primary: #363636;
$highlight_secondary: #1bc8c0;
$success: #0fdc98;
$white: #fff;
$subtle: rgb(163, 163, 163);
$subtle_light: rgb(238, 238, 238);
$translucent: #ffffff40;
