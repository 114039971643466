@import "../../utils/styles/variables/combined-vars.scss";
.credit {
  padding: $spacing-sm;
  padding-top: $spacing-xs;
  background: $translucent;
  backdrop-filter: blur(5px);
  width: fit-content;
  position: absolute;
  bottom: 0;
  z-index: 100;
  left: 0;
  font-size: $spacing-text-sm;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}
