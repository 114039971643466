@import "../../utils/styles/variables/combined-vars.scss";
@import "../../utils/styles/break-points.scss";
.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__title {
    color: $primary;
    font-weight: 400;
  }
  &__description {
    @include respond-to("small") {
      width: 70%;
    }
    font-weight: normal;
    margin-top: 1rem;
  }
  &__section {
    flex: 1;
    display: flex;
    flex-direction: column;
    &:nth-of-type(2) {
      justify-content: center;
      align-items: center;
      @include respond-to("small") {
        height: 65%;
        align-items: flex-start;
      }
    }
  }
  &__content {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: $spacing;
    @include respond-to("small") {
      align-items: start;
      text-align: left;
      flex-direction: row;
    }
  }
}
@keyframes bg-animation {
  0% {
    background-position: 20%;
  }
  100% {
    background-position: 60%;
  }
}
